




























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import api from "@/api";
import { CmsContentDetailDto } from "@/api/appService";

@Component({ name: "PreviewContent" })
export default class PreviewContent extends Vue {
    @Prop({required: true})
    contentId!: number;

    show = false;
    loading = false;
    isReviewDescription = 'PC';
    cmsContentDetail: CmsContentDetailDto = {
      title: '',
      publishTime: undefined,
      description: ''
    }

  @Watch("contentId")
    async onContentIdChange(newVal:number) {
      if(newVal) {
        this.loading = true;
        await api.cmsContent.getDetail({id: newVal}).then(res => {
          this.cmsContentDetail = res;
          this.loading = false;
        })
      }
  }

  handleReviewDescription(isPc: string) {
    switch (isPc) {
      case "PC":
        this.isReviewDescription = "PC";
        break;
      case "Phone":
        this.isReviewDescription = "Phone";
        break;
    }
  }

  handleCancel() {
    this.show = false;
  }
}
